<template>
  <div class="page">
      <div class="header">
        <el-form inline size="mini">
          <el-form-item label="Language" style="margin-bottom: 0">
            <el-select v-model="editData.languageType" placeholder="" @change="getList('')" :disabled="!current">
              <el-option v-for="item in langData" :key="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="right-box">
          <div class="update-info" v-if="current && pushed">{{pName}} last published on {{pTime | formatTime}}</div>
          <div class="edit-info" v-if="current && editData.modifyTime">{{editData.opUname}} last updated on {{editData.modifyTime | formatTime}}</div>
          <el-button type="primary" size="mini" @click="publish">Publish All</el-button>
          <el-button :type="pushed? 'info': 'success'" size="mini" v-if="current" :disabled="pushed" @click="publish(1)">{{pushed? 'Published': 'Publish'}}</el-button>
          <el-button type="danger" size="mini" @click="del(current)" v-if="current">Delete</el-button>
          <!-- <el-button type="primary" size="mini">Edit</el-button> -->
          <el-button type="success" size="mini" @click="setEdit({ language: [],matchType: 2})">Create</el-button>
        </div>
      </div>
    <div class="page-info">
    <div class="side">
      <div class="side-item" :class="{active: current === item.id}" v-for="item in list" :key="item.id" @click="setEdit(item)">{{item.name}}</div>
      <div class="empty" v-if="list.length === 0">No Data</div>
    </div>
    <div class="main">
      <div class="content">
        <div class="name">
          Name: <el-input v-model="editData.title" size="mini" placeholder="Please input"></el-input>
          <div class="sort">Sort: <el-input v-model="sort" style="width: 100px;" size="mini"/></div>
         <span class="label">Match Type:</span> 
         <el-select v-model="matchType" placeholder="Select" size="mini">
            <el-option v-for="(item, key) in matchTypeList" :key="key" :value="+key" :label="item"></el-option>
          </el-select>
        </div>
        <div class="info">
          <div class="eidter-wrap" :key="current+'edit'+editData.languageType">
            <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="editData.text"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
          </div>
        </div>
      </div>
      <div class="bottom">
        <!-- <el-button type="" size="mini">Cancel</el-button> -->
        <el-button type="primary" size="mini" @click="save">Save</el-button>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {langData, matchType} from '@/utils/constants'
import {i18nChangeLanguage} from '@wangeditor/editor'
import { Editor, Toolbar} from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import { sportRuleList, sportRuleSave, sportRuleDel, sportRulePublish } from '@/service/standard'
export default {
  data() {
    const token = this.$store.getters.token
    const tips = this.$message
    return {
      langData,
      matchTypeList: matchType,
      matchType: '',
      list: [],
      current: '',
      sort: '',
      pName: '',
      pTime: '',
      pushed: false,
      editData: {
        languageType: 'ENG'
      },
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: { 
        excludeKeys: ['insertVideo', 'uploadVideo', 'group-video']
      },
      editorConfig: { placeholder: 'Please input...', MENU_CONF: {
          uploadImage: {
            server:  `${process.env.VUE_APP_BASE_URL}/data-support/fileUploadManager/fileUpload`,
            fieldName: 'image',
            headers: {
              token
            },
            customInsert(res, insertFn) {
              console.log(res)
              if(res.code === 0) {
                const url = res.data
                insertFn(url, '', url)
              } else {
                tips.error(res.message)
              }
            }
          }
        } },
      mode: 'default', // or 'simple'
    }
  },
  components: {
     Editor,
     Toolbar
  },
  methods: {
      publish(flag) {
        const params = {}
        if(flag === 1) {
          params.id = this.current
        } 
        sportRulePublish(params).then(res => {
          if(res.code === 0) {
            this.$message.success('Success')
            this.getList()
          }
        })
      },
      onCreated(editor) {
        i18nChangeLanguage('en')
          this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      getList(id, name) {
        sportRuleList({current: 1, size: 1000, languageType: this.editData.languageType || 'ENG'}).then(res => {
          if(res.code === 0) {
            const list = res.data.records
              this.list = list
              let data = list[0]
              if(!data) {
                data = {
                  id: this.current,
                  sort: id? this.sort: '',
                  language: id? [] : [{languageType: 'ENG'}]
                }
              }
              if(name && list.some(item => item.name === name)) {
                data = list.find(item => item.name === name)
              }
              if(this.current) {
                data = list.find(item => item.id === this.current)
              }
              this.setEdit(data)

          }
        })
      },
      setEdit(item) {
        console.log(item)
        this.current = item.id
        this.sort = item.sort
        this.pushed = item.pushed
        this.pName = item.pushUname
        this.pTime = item.pushTime
        this.matchType = item.matchType
        this.editData = item.language[0] || {languageType: 'ENG', title: '', text: '' }
      },
      del(id) {
        this.$confirm('Are you sure delete this rule?').then(e => {
          if(e === 'confirm') {
              sportRuleDel(id).then(res => {
                if(res.code === 0) {
                  this.$message.success('success')
                  this.current = ''
                  this.getList()
                }
              })
            }
        })
        
      },
       save(del) {
        const params = del === 1? {id: this.current, language: []} :{id: this.current, sort: this.sort,pushed: this.pushed, matchType: this.matchType, language:[{...this.editData}]}
        if(!this.editData.title) {
          return this.$message.error('Please input name')
        }
        sportRuleSave(params).then(res => {
          if(res.code === 0) {
            this.$message.success('success')
            this.getList('', !this.current && this.editData.title)
          }
        })
      },
  },

  beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
   .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding-left: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    .right-box{
      display: flex;
      align-items: center;
      > div{
        color: #333;
        margin: 0 10px;
      }
    }
  }
  .page-info{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border: 1px solid #f0f0f0;
    flex-wrap: nowrap;
  }
  .side{
    width: 240px;
    flex-shrink: 0;
    border-right: 1px solid #f0f0f0;
    margin-right: 10px;
    padding-top: 8px;
    height: calc(100vh - 250px);
    overflow-y: auto;
    .empty{
      margin: 50% auto;
      text-align: center;
      color: #999;
    }
    .side-item {
      margin: 4px;
      border: 1px solid #f0f0f0;
      padding: 4px;
      cursor: pointer;
      color: #333;
      &.active{
        border-color: #409EFF;;
        color: #409EFF;;
      }
    }
  }
  .main{
    width: 100%;
    flex: 1;
    border-right: 1px solid #f0f0f0;
    margin-right: 1px;
    .content {

      .name {
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: #333;
        margin-bottom: 5px;
        .el-input,.el-select{
          margin-left: 10px;
        }
        .sort{
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        .label{
          flex-shrink: 0;
          margin-left: 10px;
        }
      }
      .info{
        color: #333;
        .eidter-wrap{
          border: 1px solid #f0f0f0;
          z-index: 9;
        }
      }
    }
    .bottom{
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
    }
  }
}
</style>
